@import "../../utils/styles/globals";

.wrapper {
  background: $white;
  padding: 40px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 50px 80px;

  .header {
    border-bottom: 1px solid $new-light-gray;
    margin-left: -40px;
    margin-right: -40px;
    padding: 0 20px 20px;
    display: flex;
    justify-content: space-between;

    .actions {
      display: flex;
      height: fit-content;

      button {
        margin-left: 20px;
      }
    }

    h1 {
      font-weight: bold;
    }

    .getbackButton {
      padding-left: 0;
      border: 0;
      background: none;
      text-decoration-line: none;
      cursor: pointer;

      svg {
        margin-right: 4px;
      }
    }
  }

  .content {
    margin-top: 15px;

    .search {
      max-width: 280px;
    }

    .table {
      margin: 20px 0;

      h2 {
        margin: 12px 0;
      }
      .rowHeader {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $new-light-gray;
        padding: 5px 0 16px 0;
        justify-content: space-between;

        div {
          &:nth-child(1) {
            flex: 5%;
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            flex: 19%;
          }
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $new-light-gray;
        padding: 16px 0;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          background-color: rgba(171, 171, 171, 0.1);
        }

        div {
          padding-right: 10px;

          &:nth-child(2),
          &:nth-child(3) {
            @include truncateWithNumberLine(1);
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:nth-child(1) {
            flex: 5%;
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            flex: 19%;
          }
          &:nth-child(5),
          &:nth-child(6) {
            padding-left: 20px;
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
    }
  }
}
