.container {
  background-color: #3c405f;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .mainContent {
    display: flex;
    flex-flow: row nowrap;
    width: 70vw;
    justify-content: space-between;

    @media (max-width: 480px) {
      width: 90vw;
    }

    .firstContent {
      flex: 3;
      margin: 0 2rem 0 0;
      text-align: center;

      .title {
        width: 70%;
        color: #3c405f;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 32px auto;
      }

      @media (max-width: 980px) {
        display: none;
      }
    }

    .secondContent {
      flex: 2;
    }

    .card {
      background-color: white;
      border-radius: 10px;
      height: 553px;
      max-height: 80vh;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;

      .illustration {
        width: 100%;
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;

        svg {
          width: 100%;
          max-width: 680px;
          position: relative;
          margin: 0 1.5rem 4rem;
        }
      }

      .cardHeader {
        background-color: #ffde7c;
        color: #3c405f;
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 1.1rem;
        line-height: 49px;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
        height: 49px;
      }

      .formContainer {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        .logo {
          width: 50%;
          margin: 2rem auto;
        }

        .form {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          align-items: center;

          .formBlock {
            text-align: center;
            width: 280px;

            label {
              color: #3c405f;
              margin-bottom: 6px;
              display: block;
              text-align: left;
              padding-left: 5px;
            }

            input {
              height: 38px;
              width: 280px;
              max-width: 96%;
              border: 1px solid #95989a;
              border-radius: 4px;
              margin-bottom: 1rem;
              padding: 10px;

              &.error {
                border-color: #f24e54;
                margin-bottom: 0;
              }
            }

            .errorText {
              color: #f24e54;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              margin: 4px 0 11px;
              display: block;
            }
          }

          button {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
