@import "../../utils/styles/globals";

.wrapper {
  background-color: $medium-light-gray;

  .container {
    padding: 20px;
  }
}
.page {
  .logo {
    height: 20px;
  }
}
